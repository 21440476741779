<template>
  <g>
    <path
      d="M29 15C29.55 15 30 14.55 30 14V8C30 7.45 29.55 7 29 7H3C2.45 7 2 7.45 2 8V14C2 14.55 2.45 15 3 15C3.55 15 4 15.45 4 16C4 16.55 3.55 17 3 17C2.45 17 2 17.45 2 18V24C2 24.55 2.45 25 3 25H29C29.55 25 30 24.55 30 24V18C30 17.45 29.55 17 29 17C28.45 17 28 16.55 28 16C28 15.45 28.45 15 29 15ZM28 13.17C26.84 13.58 26 14.7 26 16C26 17.3 26.84 18.42 28 18.83V23H10.75C10.75 22.59 10.41 22.25 10 22.25C9.59 22.25 9.25 22.59 9.25 23H4V18.83C5.16 18.42 6 17.3 6 16C6 14.7 5.16 13.58 4 13.17V9H9.25C9.25 9.41 9.59 9.75 10 9.75C10.41 9.75 10.75 9.41 10.75 9H28V13.17Z"
    />
    <path
      d="M10 12C10.4142 12 10.75 11.6642 10.75 11.25C10.75 10.8358 10.4142 10.5 10 10.5C9.58579 10.5 9.25 10.8358 9.25 11.25C9.25 11.6642 9.58579 12 10 12Z"
    />
    <path
      d="M10 14.37C10.4142 14.37 10.75 14.0342 10.75 13.62C10.75 13.2058 10.4142 12.87 10 12.87C9.58579 12.87 9.25 13.2058 9.25 13.62C9.25 14.0342 9.58579 14.37 10 14.37Z"
    />
    <path
      d="M10 16.75C10.4142 16.75 10.75 16.4142 10.75 16C10.75 15.5858 10.4142 15.25 10 15.25C9.58579 15.25 9.25 15.5858 9.25 16C9.25 16.4142 9.58579 16.75 10 16.75Z"
    />
    <path
      d="M10 19.13C10.4142 19.13 10.75 18.7942 10.75 18.38C10.75 17.9658 10.4142 17.63 10 17.63C9.58579 17.63 9.25 17.9658 9.25 18.38C9.25 18.7942 9.58579 19.13 10 19.13Z"
    />
    <path
      d="M10 21.5C10.4142 21.5 10.75 21.1642 10.75 20.75C10.75 20.3358 10.4142 20 10 20C9.58579 20 9.25 20.3358 9.25 20.75C9.25 21.1642 9.58579 21.5 10 21.5Z"
    />
  </g>
</template>
